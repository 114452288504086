import * as React from "react"
import {
    ChakraProvider,
    theme,
} from "@chakra-ui/react"
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import MarketingLandingPage from "./pages/MarketingLandingPage";
export const App = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <MarketingLandingPage/>
        },
        {
            path: "/Amphenol",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Andreini",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Arrow_Benefits",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Aware_Health",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Beta_Testers",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Brokers",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Evraz",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Forge_Global",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Hub_International",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Impulsum_Ventures",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Innerstave",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Kairos",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Munger_Farms",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Oura",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Redwood_Community_Services",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Shakopee_Mdewakanton_Sioux_Community",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Staywell",
            element: <MarketingLandingPage/>,
        },
        {
            path: "/Western_Allied_Mechanical",
            element: <MarketingLandingPage/>,
        },
    ], {basename: "/for"});

    return (
        <ChakraProvider theme={theme}>
            <RouterProvider router={router}/>
        </ChakraProvider>
    )
}
