import React, {useEffect, useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {
    FormControl,
    VStack,
    Input,
    Button,
    Center,
    Select,
    Flex,
    Heading,
    FormErrorMessage,
    Stack, Text,
} from '@chakra-ui/react';
import {months, sexes} from "../strings/SelectOptions";
import {useLocation} from "react-router-dom";

type FormData = {
    firstname: string;
    lastname: string;
    birth_month?: number;
    birth_day?: number;
    birth_year?: number;
    birth_date: string;
    sex: number;
    email: string;
    group: string;
};

const MyForm: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: {errors, isSubmitting}
    } = useForm<FormData>();
    const {pathname} = useLocation();
    const re = /_/;
    const group = pathname.substring(1, pathname.length).replace(re, " ")

    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [submitError, setSubmitError] = useState(false)
    useEffect(() => {

    }, [submitSuccess])

    const onSubmit: SubmitHandler<FormData> = async (formData) => {
        var birthDate;
        if (formData.birth_year && formData.birth_month && formData.birth_day) {
            birthDate = new Date(
                formData.birth_year,
                formData.birth_month,
                formData.birth_day,
            )
        }
        setSubmitError(false)

        try {
            formData.group = group;
            console.log("onSubmit: " + formData.group)
            formData.sex = Number(formData.sex)
            if (birthDate) {
                formData.birth_date = birthDate.toISOString().split("T")[0]
            }
            const response = await fetch('https://api.awarehealth.io/landing', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                // Request succeeded
                setSubmitSuccess(true)
            } else {
                // Request failed
                setSubmitError(true)
            }
        } catch (error) {
            setSubmitError(true)
        }
    };

    return (
        <>
            {!submitSuccess &&
                <form onSubmit={handleSubmit(onSubmit)}>

                    <VStack
                        spacing='28px'
                        width="100%"
                        px={{base: 2, md: 50}}>
                        <FormControl
                            isInvalid={!!errors.firstname}
                        >
                            <Input
                                id="firstname"
                                placeholder={"First Name"}
                                {...register("firstname", {
                                    required: "Required",
                                })}
                            />
                            <FormErrorMessage>
                                {errors.firstname && errors.firstname.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={!!errors.lastname}
                        >
                            <Input
                                type='lastname'
                                placeholder={'Last Name'}
                                {...register('lastname', {
                                    required: "Required",
                                })}
                                mt={[2, 2, 0, 0]}
                            />
                            <FormErrorMessage>
                                {errors.lastname && errors.lastname.message}
                            </FormErrorMessage>
                        </FormControl>
                        <Flex
                            width="100%"
                            direction={{base: 'column', lg: 'row'}}
                            alignContent="space-between"
                        >
                            <Stack
                                flex="1"
                                mr={{base: 0, lg: 2}}
                            >
                                <FormControl
                                    isInvalid={!!errors.birth_month}
                                >
                                    <Select
                                        placeholder='Birth Month'
                                        {...register("birth_month", {
                                            required: "Required"
                                        })}
                                        mt={[2, 2, 0, 0]}
                                    >
                                        {months.map((month) => (
                                            <option key={month.value} value={month.value}>
                                                {month.label}
                                            </option>
                                        ))}
                                    </Select>
                                    <FormErrorMessage>
                                        {errors.birth_month && errors.birth_month.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </Stack>
                            <Flex
                                flex="2"
                            >
                                <Stack
                                    flex="1"
                                    mr={{base: 2, md: 0}}
                                >
                                    <FormControl
                                        isInvalid={!!errors.birth_day}
                                    >
                                        <Input
                                            mt={[8, 8, 8, 0]}
                                            placeholder={'Birth Day'}
                                            type='number'
                                            {...register('birth_day', {
                                                required: "Required",
                                                min: {value: 1, message: "Please enter a valid day"},
                                                max: {value: 31, message: "Please enter a valid day"},
                                            })} />
                                        <FormErrorMessage>
                                            {errors.birth_day && errors.birth_day.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Stack>
                                <Stack
                                    flex="1"
                                    ml={{base: 0, sm: 2}}
                                >
                                    <FormControl
                                        isInvalid={!!errors.birth_year}
                                    >
                                        <Input
                                            mt={[8, 8, 8, 0]}
                                            placeholder={'Birth Year'}
                                            type='number'
                                            {...register('birth_year', {
                                                required: "Required",
                                                min: {value: 1900, message: "Please enter a valid year"},
                                                max: {value: 2023, message: "Please enter a valid year"},
                                            })} />
                                        <FormErrorMessage>
                                            {errors.birth_year && errors.birth_year.message}
                                        </FormErrorMessage>
                                    </FormControl>
                                </Stack>
                            </Flex>
                        </Flex>
                        <FormControl
                            isInvalid={!!errors.sex}
                        >
                            <Select
                                flex="1"
                                placeholder='Sex'
                                mr={{base: 0, lg: 2}}
                                mt={[2, 2, 0, 0]}
                                mb={{base: 0, lg: 0}}
                                {...register("sex", {
                                    required: "Required"
                                })}>
                                {sexes.map((month) => (
                                    <option key={month.value} value={month.value}>
                                        {month.label}
                                    </option>
                                ))}
                            </Select>
                            <FormErrorMessage>
                                {errors.sex && errors.sex.message}
                            </FormErrorMessage>
                        </FormControl>
                        <FormControl
                            isInvalid={!!errors.email}
                        >
                            <Input
                                placeholder={'Email'}
                                {...register('email', {
                                    required: "Required",
                                    pattern: {
                                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                        message: "Please enter a valid email address"
                                    }
                                })}
                                mt={[2, 2, 0, 0]}
                            />
                            <FormErrorMessage>
                                {errors.email && errors.email.message}
                            </FormErrorMessage>
                        </FormControl>
                    </VStack>
                    <Center>
                        <Button
                            type="submit"
                            color='white'
                            bg='#2AC3D7'
                            marginTop='15px'
                            isLoading={isSubmitting}
                        >Submit</Button>
                    </Center>
                    { submitError &&
                    <Center>
                        <Text color="red">
                            An error occured while submitting. Please try again later.
                    </Text>
                </Center>
                    }
                </form>
            }
            {
                submitSuccess &&
                <Flex
                    direction="column"
                    alignContent="space-around"
                    alignItems="center"
                    mx={[4, 4, 8, 10]}>
                    <Heading flex='1' as='h2' size='lg'>
                        Thanks for connecting with us!</Heading>
                    <Heading flex='1' as='h3' size='md'>You'll receive an email from us shortly.</Heading>
                </Flex>
            }


        </>
    )
        ;
};

export default MyForm;
