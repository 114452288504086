
export const PROGRAM_DETAIL_CONSTANTS = {
    DETAIL1: "We are a team of doctors of physical therapy and medical doctors experienced in diagnosing and treating muscle, nerve and joint injuries and pain utilizing a holistic approach.",
    DETAIL2: "Complete a one-on-one telehealth evaluation from Doctors of Physical Therapy, where you will set meaningful goals and receive an evidence-based treatment approach tailored to your needs.",
    DETAIL3: "Our treatment programs aim to alleviate pain and improve overall wellness by incorporating movement and lifestyle modifications, as well as addressing any psychological factors that may contribute to the pain."
}

export const TESTIMONIAL_CONSTANTS = {
    TESTIMONIAL1: "I have been seeing my provider at Aware Health for a month.  I have suffered with radiating back pain for years, I've tried steroid injections, various physical therapists, acupuncture and massage therapy with minimal help. Through this program @ Aware Health, I have already experienced great results. My pain is less, my confidence in my body is coming back, and mentally, I am feeling more upbeat about my outcome with chronic back pain. Thank you for helping me!"
}

export const FOOTER_CONSTANTS = {

}
