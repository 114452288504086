import React from 'react';
import {Image, Text, VStack} from '@chakra-ui/react';

interface Content {
    img: string;
    text: string;
}

export default function Header(props: Content) {
    return (
        <VStack
            px={[10, 10, 10, 10]}
            pb={[10, 10, 20]}
        >
                <Image
                    src={props.img}
                />
                <Text
                >
                    {props.text}
                </Text>
        </VStack>
    );
}
