import React from 'react';
import {
    VStack,
    Box, Flex, Center, Heading, Image, Text, HStack,
} from '@chakra-ui/react';
import Header from "../components/Header";
import ProgramDetail from "../components/ProgramDetail"
import LandingForm from "../components/LandingForm";
import {PROGRAM_DETAIL_CONSTANTS, TESTIMONIAL_CONSTANTS} from "../strings/strings";
import Footer from "../components/Footer";
import SupportedRegions from "../components/SupportedRegions";

const bgLight = '#F5F5F5';


export default function MarketingLandingPage() {
    return (
        <>
            <Box bg={bgLight}
            pb={{base:6, md:26}}>
                <Header/>
                <Flex
                    px={{base: 4, lg: 30, xl: 50}}
                    direction={{base: 'column', md: 'row'}}
                >
                    <Center flex='1' width='full'>
                        <VStack>
                            <Box
                                mx={{base: 2, md: 10}}
                                alignItems={"start"}>
                                <Heading as='h2' size='xl'>Pain-Free and Empowered:</Heading>
                                <Heading as='h2' size='xl'>Live More with Aware Health</Heading>
                            </Box>
                            <Image
                                src='/family.svg'
                                mt={{base: 2, md: 5}}
                                px={{base: 0, md: 10, lg: 50, xl: 150}}
                            />
                            <Text
                                mt={{base: 6, md: 10, lg: 42}}
                                mx={{base: 0, md: 6, lg: 50}}
                            >Equip yourself with the knowledge to understand your pain, obtain a quick diagnosis, learn
                                effective strategies to reduce discomfort, and reclaim your life without limitations
                                with
                                Aware Health’s expert providers.</Text>
                        </VStack>
                    </Center>
                    <Box flex='1' py={{base: 6, md: 20}}>
                        <LandingForm/>
                    </Box>
                </Flex>
            </Box>
            <Box
                mt={{base: 16, md: 20, lg: 76}}
            >
                <Center>
                    <Heading as={'h1'} size='xl'>
                        Our Program
                    </Heading>
                </Center>
                <Flex
                    mx={{base: 2, md: 25}}
                    my={{base: 8, md: 30}}
                    direction={{base: 'column', md: 'row'}}>
                    <Box flex='1'>
                        <ProgramDetail
                            img={'/people_running.svg'}
                            text={PROGRAM_DETAIL_CONSTANTS.DETAIL1}
                        />
                    </Box>
                    <Box flex='1'>
                        <ProgramDetail
                            img={'/lady_waving.svg'}
                            text={PROGRAM_DETAIL_CONSTANTS.DETAIL2}
                        />
                    </Box>
                    <Box flex='1'>
                        <ProgramDetail
                            img={'/yoga_group.svg'}
                            text={PROGRAM_DETAIL_CONSTANTS.DETAIL3}
                        />
                    </Box>
                </Flex>

                <Center>
                    <SupportedRegions/>
                </Center>
            </Box>
            <Flex
                mt={{base: 6, md: 26}}
                mx={{base: 2, md: 100}}
                my={{base: 8, md: 30}}
                direction={{base: 'column', md: 'row'}}>
                <Image
                    flex='1'
                    src='/man_stretching.svg'
                    alignSelf='center'
                    mt={{base: 2, md: 5}}
                    boxSize={{base: 350, md: 200}}
                />
                <Center
                    flex='1'
                    pl={{base: 4, md: 50}}
                    pr={{base: 12, md: 12, lg: 20}}
                >
                    <HStack
                        justifyItems='left'
                        style={{alignItems: 'baseline'}}
                        mt={{base: 6, sm: 0}}>
                        <Image
                            justifySelf='left'
                            src={'/double_quotes.svg'}
                        />
                        <Text
                            align={'center'}>
                            {TESTIMONIAL_CONSTANTS.TESTIMONIAL1}
                        </Text>
                    </HStack>
                </Center>
            </Flex>
            <Box
                mt={{base: 6, md: 28, lg: 36}}
                mx={{base: 6, md: 0}}
            >
                <Footer/>
            </Box>
        </>
    );
}
