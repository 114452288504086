import React from 'react';
import {Box, Image} from "@chakra-ui/react";

export default function Header() {
    return (
        <Box
            pt={[2,10,30,30]}
            mx={[2,10,10,10]}
        >
            <Image
                src={'/aware-logo-dark.svg'}
            />
        </Box>
    );
}
