import React from 'react';
import {Text, Center, Heading, Image, VStack, Stack} from "@chakra-ui/react";
import {COLORS} from "../colors/colors";

export default function SupportedRegions() {
    const boxWidth = '180px'
    const boxMarginX = {base:0, md:2}
    return (
        <Stack
            direction={{base: 'column'}}
            mx={[4, 2, 2, 2]}>
            <Center
            justifyContent='center'>
                <Heading
                    as='h2'
                    size={['md', 'md', 'xl', 'xl']}
                    mx={[6,4,4,4]}
                >The only solution that works for everyone</Heading>
            </Center>
            <Center>
                <Text
                    size={['md', 'md', 'xl', 'xl']}
                    mx={[6,4,4,4]}
                >
                    We treat all regions even pelvic floor, including
                </Text>
            </Center>
            <Center
                my={[6,6,10,10]}>
                <Stack
                    direction={{base: 'column', lg: 'row'}}>
                    <Center
                        boxSize={[boxWidth]}
                        rounded='30'
                        border='1px'
                        borderColor={COLORS.LIGHT_GRAY}
                        m={boxMarginX}
                    >
                        <VStack>
                            <Image
                                flex='1'
                                boxSize={['110px']}
                                src={'/region_back.svg'}
                            />
                            <Text>Back</Text>
                        </VStack>
                    </Center>

                    <Center
                        boxSize={boxWidth}
                        rounded='30'
                        border='1px'
                        borderColor={COLORS.LIGHT_GRAY}
                        m={boxMarginX}
                    > <VStack>
                        <Image
                            flex='1'
                            src={'/region_ankle.svg'}
                        />
                        <Text>Ankle</Text>
                    </VStack>
                    </Center>
                    <Center
                        boxSize={boxWidth}
                        rounded='30'
                        border='1px'
                        borderColor={COLORS.LIGHT_GRAY}
                        m={boxMarginX}
                    >
                        <VStack>
                            <Image
                                flex='1'
                                src={'/region_wrist.svg'}
                            />
                            <Text>Wrist/Hand</Text>
                        </VStack>
                    </Center>
                    <Center
                        boxSize={boxWidth}
                        rounded='30'
                        border='1px'
                        borderColor={COLORS.LIGHT_GRAY}
                        m={boxMarginX}
                    >
                        <VStack>
                            <Image
                                flex='1'
                                src={'/elbow.svg'}
                            />
                            <Text>Elbow</Text>
                        </VStack>
                    </Center>
                    <Center
                        boxSize={boxWidth}
                        rounded='30'
                        border='1px'
                        borderColor={COLORS.LIGHT_GRAY}
                        m={boxMarginX}
                    >
                        <VStack>
                            <Image
                                flex='1'
                                src={'/region_neck.svg'}
                            />
                            <Text>Neck</Text>
                        </VStack>
                    </Center>
                </Stack>
            </Center>
        </Stack>
    );
}