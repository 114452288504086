import React from 'react';
import {Divider, Flex, Image, Stack, Text, VStack, Link} from "@chakra-ui/react";

export default function Footer() {
    return (
        <Flex
            pt={[2, 10, 30, 30]}
            //mx={[2,10,10,10]}
            mx={{base: 2, md: 100}}
            mb={{base: 6, md: 36}}
            direction={{base: 'column'}}
        >
            <Image
                src={'/aware-logo-dark.svg'}
                width='184px'
            />
            <Divider
                pt={[2, 2, 2, 2]}
                pb={[2, 4, 4, 4]}
                orientation='horizontal'/>
            <VStack
                alignItems='baseline'>
                <Stack
                    pt={[2, 6, 6, 6]}
                    direction='row'
                    alignSelf='normal'>
                    <Text
                        alignSelf='normal'>
                        Aware Health | 3359 Mt Diablo Blvd, Suite 151 | Lafayette, CA, 94548 | USA
                    </Text>
                    <Divider
                        orientation='vertical'
                        size='200px'/>

                </Stack>
                <Text>
                    getwell@awarehealth.io
                </Text>
                <figure>
                    <Link href="https://www.techstars.com">
                        <Image
                            src={'/techstars.png'}
                            width='150px'
                            />
                    </Link>
                </figure>
            </VStack>

        </Flex>
    );
}
